
import { defineComponent, reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import demandApi from '@/axios/api/demandApi'
import proposalApi from '@/axios/api/proposal'
import { useRouter, useRoute } from 'vue-router'
import store from '@/store/index'
export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const pageData = reactive({
      phone: '',
      code: '',
      sendCodeText: '发送验证码',
    })
    pageData.phone = route.query.phone as string
    const sendCode = () => {
      if (pageData.sendCodeText !== '发送验证码') {
        return
      }
      if (
        !pageData.phone ||
        !/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(
          pageData.phone
        )
      ) {
        message.warning('手机号为空或手机号格式不正确')
        return
      }
      proposalApi
        .SendCode({ phone: pageData.phone })
        .then((res) => {
          message.success('发送成功')
          let time = +res
          let timer = setInterval(() => {
            if (time > 0) {
              pageData.sendCodeText = --time + '秒'
            } else {
              //倒计时 时间到 重新绑定验证事件  清楚定时器
              pageData.sendCodeText = '发送验证码'
              clearInterval(timer)
            }
          }, 1000)
        })
        .catch((err) => {
          message.error(err)
        })
    }
    const onSubmit = () => {
      if (!pageData.phone || !pageData.code) {
        message.warning('手机号或验证码为空')
        return
      }
      proposalApi
        .Approve({
          phone: pageData.phone,
          code: pageData.code,
        })
        .then(() => {
          if (store.state.demandForm) {
            demandApi
              .ProposalCustomerAdd(store.state.demandForm)
              .then(() => {
                message.success('提交成功')
                store.commit('setDemandForm', {})
                router.push(`/requirementsGathering/success`)
              })
              .catch((err) => {
                message.error(err)
              })
          } else {
            router.push('/requirementsGathering/demandForm')
          }
        })
        .catch((err) => {
          message.error(err)
        })
    }
    return {
      ...toRefs(pageData),
      sendCode,
      onSubmit,
    }
  },
})
